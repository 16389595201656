.floatingDownloadButtonContainer {
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: #fff;
    -webkit-box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
    padding: 10px 20px;
}

.floatingDownloadButtonText {
    background-image: linear-gradient(to top,
            #62258a 67%,
            #b3097f 11%);
    font-family: var(--font-primary), sans-serif;
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: -0.22px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    text-align: center;
    animation: blinking 1s linear infinite alternate-reverse;
    margin-bottom: 10px;
}

.floatingDownloadButtonTextStory{
    font-weight: 500;    
    font-size: clamp(12px, 3.5vw, 85px);
    color: #ffffffcc;
    text-align: center;
    font-family: var(--font-primary);
}

.floatingDownloadButton {
    position: fixed;
    top: 50px;
    left: 0;
    height: calc(100% - 150px); 
    width: 100%;
    background-color: #fff;
}

.floatingDownloadButtonContainerStory .floatingDownloadButton{
    height: calc(100% - 160px); 
}

.floatingDownloadButtonFB {
    height: fit-content;
}

.offerPopupClose {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
}

.videoView {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center
}

.videoViewFB {
    height: 390px;
}

.videoSoundControlViewForStory {
    position: absolute;
    top: 32px;
    right: 50px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
    background-color: #ccc;
    padding: 2px
}
.videoSoundControlView {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
    background-color: #ccc;
    padding: 2px
}

.storyBarContainer{
    display: flex;
    grid-gap: 5px;
    justify-content: space-between;
    position: absolute;
    z-index: 9;
    margin: 2%;
    width: 96%;
}

.complete {
    background: #fff;
}
  
.active {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
}

.storyBar{
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background: #ffffff4c;
    position: relative;
    overflow: hidden;
}

  @keyframes fill {
    from {
      transform: translateX(-100%)
    }
  
    to {
      transform: translateX(0%)
    }
  }

.storyAnimationRunning {
    animation-play-state: running;
}

.storyAnimationPaused {
    animation-play-state: paused;
}

.howToInstallLabel{
    position: absolute;
    z-index: 10;
    color: white;
    top: 30px;
    left: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: var(--font-primary);
    text-shadow: 0 2px 0px rgba(0,0,0,1);
}

@keyframes blinking {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@media screen and (min-width: 767px) {
    .floatingDownloadButtonContainer {
        display: none !important;
    }
}

.floatingDownloadButtonContainerStory{
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to right, rgb(55 27 85) 0%, rgb(160 147 175) 50%, rgb(55 27 85) 100%);
    border-image-slice: 1;
    height: 110px;
    background: #210142;
}